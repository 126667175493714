import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  connectInfiniteHits,
  SearchBox,
  connectStateResults,
} from 'react-instantsearch-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import loadable from '@loadable/component';

import constructFabricUrl from 'commons/constructFabricUrl';
import addDomainToPath from 'commons/addDomainToPath';
import { COLOR_CODES_FABRIC } from 'commons/constants';
import JBImage from 'components/essentials/JBImage';
import IconPlus from 'components/icons/iconPlus';
import imageUrls from 'commons/image-urls.json';
import useScreen from 'hooks/useScreen';
import MasonryPhotoCard from '../photo-card/PhotoCard';

const Masonry = loadable(() => import('react-masonry-component'), {
  ssr: false,
});

let MATERIALS_DATA = null;

export const EXCLUDED_FILTER_PILLS = ['color', 'material', 'room'];

export const FACET_FILTER_VALUES = {
  room: 'photo_room:',
  material: 'products.material:',
  color: 'products.option_color:',
  upholstery: 'products.upholstery:',
  product_id: 'products.product_id:',
  option_value: 'products.upholstery:',
};

export const FACET_FILTER_VALUES_SEARCH = {
  room: 'photo_room:',
  material: 'material:',
  option_color: 'option_color:',
  type: 'type:',
  product_material: 'option_name:',
};

const imgixDomains = imageUrls.inspireImgixDomains;
export const transformInspirationImageUrl = (url = '') => {
  const domain =
    imgixDomains[
      url.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) %
        imgixDomains.length
    ];
  let path = url;
  if (url.startsWith('https://pstatic.joybird.com/')) {
    path = url.replace('https://pstatic.joybird.com/', domain);
  } else if (url.startsWith('https://inspire.imgix.net/')) {
    path = url.replace('https://inspire.imgix.net/', domain);
  } else if (url.startsWith('/ds_quiz/')) {
    path = addDomainToPath(url.slice(1));
  }
  const split = path.split('/');
  const imgName = split.pop().replace('main_', '');
  split.push(imgName);
  return split.join('/');
};

export const InspirationSearchBox = ({
  defaultRefinement,
  onProductClicked,
  resetProductSearch,
}) => {
  const { isMobile } = useScreen();

  return (
    <div className="[&_i]:w-[18px] [&_i]:h-[18px] [&_i]:text-[18px] [&_i]:block [&_i]:absolute [&_i]:top-1/2 [&_i]:mt-[-9px] [&_i]:right-10 [&_i]:cursor-pointer [&_i]:[border-right:1px_solid_#dfe0e1] bg-gray-light8 min-h-[46px] ![background-color:initial] !text-gray-light1 border-gray-light5 border !border-l-0 !border-r-0 !border-t-0 md:!border-b-0 !p-0 [&_input]:text-base [&_input]:font-bold [&_input]:!text-gray-light2 [&_input]:!p-0">
      <div
        className={classNames(
          'mx-5 my-0 flex flex-row items-stretch relative box-border min-[1440px]:mx-[3.333vw] min-[1440px]:my-0 p-0 m-0',
          '[&_.ais-SearchBox-form_.ais-SearchBox-input]:text-[.8rem] [&_.ais-SearchBox-form_.ais-SearchBox-input]:h-[44px] [&_.ais-SearchBox-form_.ais-SearchBox-input]:text-gray-dark [&_.ais-SearchBox-form_.ais-SearchBox-input]:block [&_.ais-SearchBox-form_.ais-SearchBox-input]:border [&_.ais-SearchBox-form_.ais-SearchBox-input]:border-solid [&_.ais-SearchBox-form_.ais-SearchBox-input]:border-transparent [&_.ais-SearchBox-form_.ais-SearchBox-input]:border-b-gray-light3 [&_.ais-SearchBox-form_.ais-SearchBox-input]:pt-0 [&_.ais-SearchBox-form_.ais-SearchBox-input]:pr-0 [&_.ais-SearchBox-form_.ais-SearchBox-input]:pb-0',
          '[&_.ais-SearchBox-form_.ais-SearchBox-input]:bg-search [&_.ais-SearchBox-form_.ais-SearchBox-input]:bg-no-repeat [&_.ais-SearchBox-form_.ais-SearchBox-input]:[background-position:0_50%]',
          '[&_.ais-SearchBox-form_.ais-SearchBox-input]:!pl-9 [&_.ais-SearchBox-form_.ais-SearchBox-input]:w-[310px] [&_.ais-SearchBox-form_.ais-SearchBox-input]:leading-[32px] [&_.ais-SearchBox-form_.ais-SearchBox-input]:text-lg',
          '[&_.ais-SearchBox-form_.ais-SearchBox-input::placeholder]:text-lg [&_.ais-SearchBox-form_.ais-SearchBox-input::placeholder]:text-left',
          'max-[767px]:[&_.ais-SearchBox-form_.ais-SearchBox-input]:border-b-0 max-[767px]:[&_.ais-SearchBox-form_.ais-SearchBox-input]:w-full',
          'max-[767px]:[.ais-SearchBox-input.hasValue_~_&]:!flex max-[767px]:[.ais-SearchBox-input.hasValue_~_&_svg]:fill-gray max-[767px]:[.ais-SearchBox-input.hasValue_~_&_svg]:w-3 max-[767px]:[.ais-SearchBox-input.hasValue_~_&_svg]:h-3 max-[767px]:[.ais-SearchBox-input.hasValue_~_&_.ais-SearchBox-reset]:!block md:[&_.ais-SearchBox-reset]:!hidden md:[&_.ais-SearchBox-submit]:!hidden md:[&_.ais-SearchBox-reset]:absolute md:[&_.ais-SearchBox-reset]:top-[40%] md:[&_.ais-SearchBox-reset]:right-[10px]'
        )}
      >
        <div className="inline-flex w-full">
          <SearchBox
            translations={{
              placeholder: isMobile
                ? 'Search'
                : 'Search for products or materials',
            }}
            defaultRefinement={defaultRefinement}
            onInput={event => {
              if (event.currentTarget.value.length > 0) {
                event.currentTarget.classList.add('hasValue');
              } else {
                event.currentTarget.classList.remove('hasValue');
              }
            }}
            onReset={async event => {
              const target = event.currentTarget;
              onProductClicked('');
              resetProductSearch();

              // Remove hasValue class from input
              const form = target?.closest('.ais-SearchBox-form');
              form
                ?.querySelector('.ais-SearchBox-input')
                ?.classList.remove('hasValue');
            }}
          />
        </div>
      </div>
    </div>
  );
};

InspirationSearchBox.propTypes = {
  defaultRefinement: PropTypes.string,
  onProductClicked: PropTypes.func,
  resetProductSearch: PropTypes.func,
};

const updateMaterialData = fabricsData => {
  const finalData = fabricsData.reduce((acc, currentVal) => {
    const firstChar = currentVal.option_value.toLowerCase().charAt(0);
    if (acc[firstChar]) {
      acc[firstChar].push(currentVal);
    } else {
      acc[firstChar] = [currentVal];
    }
    return acc;
  }, {});
  return finalData;
};

const returnTopThreeMatchingMaterials = query => {
  if (!query) return [];

  const lowercaseQuery = query.toLowerCase();

  const results =
    MATERIALS_DATA && MATERIALS_DATA[lowercaseQuery.charAt(0)]
      ? MATERIALS_DATA[lowercaseQuery.charAt(0)].filter(val =>
          val.option_value.toLowerCase().includes(lowercaseQuery)
        )
      : [];

  return results.splice(0, 3) || [];
};

export const InspirationSearchOptions = connectStateResults(
  ({ searchState, searchResults, fabricsData, ...otherProps }) => {
    if (!MATERIALS_DATA && !!fabricsData)
      MATERIALS_DATA = updateMaterialData(fabricsData);

    if (!searchResults) {
      return null;
    }

    const fabricMatches = returnTopThreeMatchingMaterials(searchState.query);

    if (searchResults.query.length < 1 || !searchResults.hits.length) {
      return null;
    }

    if (
      (otherProps.props.isProductIdFiltered.isFiltered &&
        searchResults.query ===
          otherProps.props.isProductIdFiltered.productName) ||
      (otherProps.props.isMaterialOptionFiltered &&
        searchState.query === otherProps.props.isMaterialOptionFiltered)
    ) {
      return null;
    }

    const noFabricResults = searchResults.hits.filter(
      product => product.type !== 'Fabric'
    );

    return (
      <div className="absolute bg-white z-[1888] w-full top-[46px] left-0 px-2 py-6 border border-solid border-gray-light7 rounded-lg max-h-[457px] overflow-auto">
        {fabricMatches.length ? (
          <div className="inspiration-search__list--section">
            <div className="text-xs font-bold text-[#74777a] mb-2 max-[767px]:text-base max-[767px]:font-bold max-[767px]:text-gray">
              Materials
            </div>
            {fabricMatches.map(matches => (
              <button
                type="button"
                key={matches.option_value_id}
                className="text-sm rounded-sm w-full flex flex-row items-center justify-start px-0 py-4 cursor-pointer border-0 [background:none] font-bold hover:bg-gray-light8 focus-visible:outline-offset-[-4px]"
                onClick={() => {
                  otherProps.props.onSearchUpdate({
                    query: matches.option_value,
                  });
                  otherProps.onMaterialOptionChange(matches.option_value);
                }}
              >
                <div className="pl-4 w-[4.5rem] [&_img]:w-full">
                  <JBImage
                    src={
                      constructFabricUrl(matches.option_value)
                        .transparent_config_image
                    }
                    width={200}
                    alt={matches.option_value}
                  />
                </div>
                <div className="pr-4 text-gray-light1 font-normal ml-6">
                  {matches.option_value}
                </div>
              </button>
            ))}
          </div>
        ) : null}

        {/* 
          Replaced searchResults with noFabricResults to prevent fabrics from appearing in Products section of search results 
          Restore if/when fabrics are removed in Algolia from products_jb4 index
        */}
        {searchResults.query.length > 0 && searchResults.hits.length ? (
          <div className="inspiration-search__list--section">
            <div className="text-xs font-bold text-[#74777a] mb-2 max-[767px]:text-base max-[767px]:font-bold max-[767px]:text-gray">
              Products
            </div>
            {noFabricResults.map(product => (
              <button
                type="button"
                key={product.product_id}
                className="text-sm rounded-sm w-full flex flex-row items-center justify-start px-0 py-4 cursor-pointer border-0 [background:none] font-bold hover:bg-gray-light8 focus-visible:outline-offset-[-4px]"
                onClick={() => {
                  otherProps.props.onSearchUpdate({ query: product.name });
                  otherProps.props.onProductClicked(
                    product.product_id,
                    product.name
                  );
                }}
              >
                <div className="pl-4 w-[4.5rem] [&_img]:w-full">
                  <JBImage
                    src={addDomainToPath(product.small_img)}
                    width={200}
                    alt={product.name}
                  />
                </div>
                <div className="pr-4 text-[#585858] font-normal ml-6">
                  {product.name}
                </div>
              </button>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
);

export const PhotoCardsResult = ({
  hasFilters,
  hasMore,
  hits,
  mobileItemsPerRow,
  multiSelectedGridItem = null,
  refine,
  selectedGridItem,
  showOnTop,
  updateHits,
  updateSelectedGridItem,
  variant = '',
  ...otherProps
}) => {
  const [activeId, setActiveId] = useState(null);
  const { isMobile, windowWidth } = useScreen();

  const infiniteScrollThreshold = windowWidth <= 768 ? '2800px' : '2200px';

  if (!hits) {
    return null;
  }

  if (hasFilters && hits.length === 0) {
    return (
      <div className="w-full flex items-center justify-center min-h-[20vh]">
        No matches found!
      </div>
    );
  }

  const formattedHits =
    hits && hits.length
      ? hits.map(hit => ({
          ...hit,
          image_link: transformInspirationImageUrl(hit.image_link),
        }))
      : hits;

  const photoCardsList = formattedHits.map(photo => {
    const finalSelGridItem =
      multiSelectedGridItem && multiSelectedGridItem[photo.photo_id]
        ? multiSelectedGridItem[photo.photo_id]
        : selectedGridItem;
    return (
      <MasonryPhotoCard
        {...otherProps}
        active={activeId === photo.photo_id}
        hits={formattedHits}
        isMobile={isMobile}
        key={photo.photo_id}
        mobileItemsPerRow={mobileItemsPerRow}
        noScrollToView={otherProps.noScrollToView ?? true}
        photo={photo}
        selectedGridItem={finalSelGridItem}
        setActiveId={setActiveId}
        showOnTop={showOnTop}
        updateSelectedGridItem={updateSelectedGridItem}
      />
    );
  });
  return (
    <>
      <InfiniteScroll
        dataLength={photoCardsList.length}
        next={otherProps.searchMore ? otherProps.searchMore : refine}
        hasMore={hasMore}
        scrollThreshold={infiniteScrollThreshold}
        scrollableTarget={otherProps.scrollableTarget}
        style={isMobile ? { height: 'initial', overflow: 'initial' } : {}}
        className={classNames({ 'my-0 -mx-4': variant === 'InspirationPage' })}
      >
        {isMobile ? (
          <div
            className={classNames(
              'customer-photos-mobile-grid grid gap-[2px] grid-cols-[repeat(auto-fill,minmax(30%,1fr))] w-full',
              {
                'max-[767px]:!grid-cols-[repeat(auto-fill,minmax(45%,1fr))]':
                  variant === 'customer-photos-grid' || mobileItemsPerRow === 2, // multiple overrides in other places, !important flag preferred over complex boolean logic
                'max-[767px]:!grid-cols-[repeat(auto-fill,minmax(95%,1fr))]':
                  mobileItemsPerRow === 1,
              }
            )}
          >
            {photoCardsList}
          </div>
        ) : (
          <div className="w-full [&>div]:overflow-hidden">
            <Masonry
              options={{ transitionDuration: 0 }}
              enableResizableChildren
              disableImagesLoaded
            >
              {photoCardsList}
            </Masonry>
          </div>
        )}
      </InfiniteScroll>
      {hasMore ? (
        <div className="products-grid__load-more">
          <button
            type="button"
            className="text-base [background:none] border-0 text-gray-light1 mt-4 p-2 hover:cursor-pointer hover:text-gray-dark focus-visible:text-gray-dark"
            onClick={refine}
          >
            Load more
          </button>
        </div>
      ) : null}
    </>
  );
};

PhotoCardsResult.propTypes = {
  hasFilters: PropTypes.bool,
  hasMore: PropTypes.bool,
  hits: PropTypes.array,
  mobileItemsPerRow: PropTypes.number,
  multiSelectedGridItem: PropTypes.object,
  noScrollToView: PropTypes.bool,
  refine: PropTypes.func,
  scrollableTarget: PropTypes.string,
  selectedGridItem: PropTypes.object,
  showOnTop: PropTypes.bool,
  updateHits: PropTypes.func,
  updateSelectedGridItem: PropTypes.func,
  variant: PropTypes.string,
};

export const PhotoCardsResultSearch = connectInfiniteHits(PhotoCardsResult);

export const removeFilterValue = (facetFilters, type, value, forIndex) => {
  const filterArray = facetFilters.split(',');
  const finalFacetVals =
    forIndex && forIndex === 'search'
      ? FACET_FILTER_VALUES_SEARCH
      : FACET_FILTER_VALUES;
  if (value) {
    return filterArray
      .filter(
        filter => filter !== `${finalFacetVals[type]}${value}` && filter !== ''
      )
      .toString();
  }
  return filterArray
    .filter(
      filter => !filter.includes(`${finalFacetVals[type]}`) && filter !== ''
    )
    .toString();
};

const toTitleCase = text => `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

const getHexColor = color =>
  COLOR_CODES_FABRIC.filter(obj => obj[color])[0][color];

export const SelectedFilter = ({ filterName, onDeselect, name, type }) => (
  <button
    type="button"
    className="text-gray flex flex-row justify-center my-1 mr-3 py-[0.4rem] px-2 font-normal b-0 bg-gray-light7 rounded focus-visible:-outline-offset-1 first:ml-0 hover:bg-gray-light9 hover:cursor-pointer"
    onClick={() => {
      if (type && type === 'search') {
        onDeselect(name, filterName);
      } else {
        onDeselect(filterName, name);
      }
    }}
  >
    <div className="flex items-center">
      {name === 'color' && (
        <div
          className="h-[15px] w-[15px] rounded-lg mx-[5px]"
          style={{
            background: getHexColor(filterName),
          }}
        />
      )}
      {toTitleCase(filterName)}
      <div className="ml-1 rotate-45">
        <IconPlus />
      </div>
    </div>
  </button>
);

SelectedFilter.propTypes = {
  filterName: PropTypes.string,
  onDeselect: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
};
